import {
  PropertyItem,
  PropertyFilter,
  PropertyType,
  ClientMatch,
  SignedClientMatch,
  ExtraItem,
} from "../types";
import BaseService from "./baseService";

export default class PropertyService extends BaseService {
  constructor() {
    super("property");
  }

  async getPropertyTypes(): Promise<PropertyType[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "Garage",
            },
            {
              id: "2",
              name: "House",
            },
          ]),
        1000
      );
    });
  }

  async getClientMatches(propertyId: string): Promise<{
    clientMatch: ClientMatch[];
    signedClientMatch: SignedClientMatch[];
  }> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            clientMatch: [
              {
                id: "1",
                name: "Paul Pogba",
                ratio: 0.72,
              },
              {
                id: "1",
                name: "Erling Holand",
                ratio: 0.44,
              },
              {
                id: "3",
                name: "Eric Cantona",
                ratio: 0.87,
              },
              {
                id: "4",
                name: "Eric Cantona",
                ratio: 0.87,
              },
              {
                id: "5",
                name: "Clarence Seedorf",
                ratio: 0.67,
              },
            ],
            signedClientMatch: [
              {
                id: "1",
                name: "Arnold Dlonra",
              },
            ],
          }),
        1000
      );
    });
  }

  async getAll(
    filter: PropertyFilter | null
  ): Promise<{ count: number; results: PropertyItem[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = [
          {
            id: "1",
            address: "13th Street. 47 W 13th St, New York, NY 10011",
            appartmentNo: 8,
            city: "New York",
            clientName: "Eugene T",
            customerPhone: "+123-456-789",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tristique quam justo, eget iaculis lorem imperdiet vel. Nam dui felis, dignissim id turpis et, iaculis vestibulum quam. Pellentesque vestibulum facilisis libero, vitae auctor nunc ornare id. Praesent congue lorem id massa eleifend sagittis. Quisque eu tristique purus. Phasellus metus ligula, fringilla vel est ut, ullamcorper iaculis diam. Cras vel ex augue. In hac habitasse platea dictumst. Mauris volutpat eu tellus vitae pellentesque.",
            extensions: [
              ExtraItem.AccessForDisabled,
              ExtraItem.Parking,
              ExtraItem.Bars,
              ExtraItem.AirConditioner,
            ],
            floor: 1,
            lastUpdate: new Date(),
            marketingDate: new Date(),
            neighborhood: "New North",
            previousPrice: 600000,
            price: 1200000,
            propertyType: "Appartment",
            rooms: 4,
            sQm: 180,
          },
          {
            id: "2",
            address: "Main str 456",
            appartmentNo: 8,
            city: "Washington",
            clientName: "Bubmle B",
            customerPhone: "+987-654-321",
            description: "Good view",
            extensions: [],
            floor: 1,
            lastUpdate: new Date(),
            marketingDate: new Date(),
            neighborhood: "New South",
            previousPrice: 600000,
            price: 600000,
            propertyType: "Private house",
            rooms: 2,
            sQm: 60,
          },
          {
            id: "3",
            address: "Main str 987",
            appartmentNo: 1,
            city: "Los Angeles",
            clientName: "Test A",
            customerPhone: "+111-111-1111",
            description: "Hollywood",
            extensions: [],
            floor: 1,
            lastUpdate: new Date(),
            marketingDate: new Date(),
            neighborhood: "N/A",
            previousPrice: 1600000,
            price: 1600000,
            propertyType: "Private house",
            rooms: 10,
            sQm: 600,
          },
          {
            id: "4",
            address: "Test str 987",
            appartmentNo: 1,
            city: "London",
            clientName: "Test B",
            customerPhone: "+222-222-2222",
            description: "Downtown",
            extensions: [],
            floor: 1,
            lastUpdate: new Date(),
            marketingDate: new Date(),
            neighborhood: "N/A",
            previousPrice: 1600000,
            price: 1600000,
            propertyType: "Private house",
            rooms: 10,
            sQm: 600,
          },
        ];
        resolve({
          count: 30,
          results,
        });
      }, 1000);
    });
  }
}
