import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Pages } from "../../enums/pages";
import { Iconly } from "react-iconly";
import "./TopNavigationBar.css";

export const TopNavigationBar = () => {
  const getNavLinkClasses = (isActive: boolean) =>
    clsx("flex items-center justify-center gap-4 top-bar-navlink", {
      "text-highlighted": isActive,
      "text-primary": !isActive,
    });
  return (
    <nav className="flex justify-center gap-10 h-full">
      <NavLink
        to={Pages.Assets}
        className={({ isActive }) => getNavLinkClasses(isActive)}
      >
        <Iconly name="Home" />
        Assets
      </NavLink>
      <NavLink
        to={Pages.Customers}
        className={({ isActive }) => getNavLinkClasses(isActive)}
      >
        <Iconly name="TwoUsers" />
        Customers
      </NavLink>
      <NavLink
        to={Pages.Calendar}
        className={({ isActive }) => getNavLinkClasses(isActive)}
      >
        <Iconly name="Calendar" />
        Calendar
      </NavLink>
      <NavLink
        to={Pages.Documents}
        className={({ isActive }) => getNavLinkClasses(isActive)}
      >
        <Iconly name="Document" />
        Documents
      </NavLink>
    </nav>
  );
};
