import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "../../services/provider";
import { getError } from "../../utils/errors";
import { Buyer } from "../../types";

interface BuyersDataSource {
  data: Buyer[];
  loading: boolean;
}

export interface BuyerStore {
  items: BuyersDataSource;
  error: string | null;
}

const initialState: BuyerStore = {
  items: { data: [], loading: false },
  error: null,
};

export const getBuyerDataSourceAsync = createAsyncThunk(
  "buyer/getBuyerItems",
  async (agentId: string) => {
    return await ServiceProvider.Buyer.getAll(agentId);
  }
);

export const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuyerDataSourceAsync.pending, (state) => {
        state.items.loading = true;
      })
      .addCase(getBuyerDataSourceAsync.fulfilled, (state, action) => {
        state.items.data = get(action, "payload", []);
        state.items.loading = false;
      })
      .addCase(getBuyerDataSourceAsync.rejected, (state, action) => {
        state.items.data = [];
        state.items.loading = false;
        state.error = getError(action);
      });
  },
});

export const makeBuyerDataSource = (state: { buyer: BuyerStore }) =>
  state.buyer.items;

export default buyerSlice.reducer;
