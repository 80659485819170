import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from "flowbite-react";
import React, { ReactNode } from "react";

import button from "./flowbite-theme/button";
import checkbox from "./flowbite-theme/checkbox";
import textInput from "./flowbite-theme/textInput";

export const customTheme: CustomFlowbiteTheme = {
  button,
  checkbox,
  textInput,
};

const Theme: React.FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  return (
    <React.Fragment>
      <Flowbite theme={{ theme: customTheme }}>{children}</Flowbite>
    </React.Fragment>
  );
};

export default Theme;
