import { Task, TaskStatus } from "../types";
import BaseService from "./baseService";

const now = new Date();
const futureDate = new Date();
futureDate.setHours(now.getHours() + 1);

const tasks = [
  {
    id: "1",
    note: "Make something special",
    status: TaskStatus.Completed,
    createdAt: now,
  },
  {
    id: "2",
    note: "Call customer",
    status: TaskStatus.InProgress,
    createdAt: futureDate,
  },
];

export default class TasksService extends BaseService {
  constructor() {
    super("tasks");
  }

  async setStatus(taskId: string, status: TaskStatus): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const task = tasks.find((t) => t.id === taskId);
        if (task) {
          task.status = status;
        }
        resolve();
      }, 1000);
    });
  }

  async getPropertyAll(propertyId: string): Promise<Task[]> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(tasks), 1000);
    });
  }

  async getBuyerAll(buyerId: string): Promise<Task[]> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(tasks), 1000);
    });
  }
}
