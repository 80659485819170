import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const UnauthorizedLayout = () => {
  const { user, locationToNavigate } = useAuth();
  const outlet = useOutlet();
  if (user) {
    return <Navigate to={locationToNavigate} />;
  }

  return <div className="w-full h-full">{outlet}</div>;
};
