import { Extra } from "../types";
import BaseService from "./baseService";

export default class ExtraService extends BaseService {
  constructor() {
    super("extra");
  }

  async getAll(): Promise<Extra[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "E1",
            },
            {
              id: "2",
              name: "E2",
            },
          ]),
        1000
      );
    });
  }
}
