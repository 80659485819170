import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div className="flex gap-1">
      <Dropdown label={t(`LanguageSwitcher.${lang}`)} inline>
        {["en", "he"].map((language) => (
          <Dropdown.Item
            key={language}
            onClick={() => {
              setLang(language);
            }}
          >
            {language}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

export default LanguageSwitcher;
