import { Tag } from "../types";
import BaseService from "./baseService";

export default class TagService extends BaseService {
  constructor() {
    super("tag");
  }

  async getAll(): Promise<Tag[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "Tag 1",
              color: "#FFEEEE",
            },
            {
              id: "2",
              name: "Tag 2",
              color: "#CCCCCC",
            },
          ]),
        1000
      );
    });
  }
}
