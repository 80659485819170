import { Note } from "../types";
import BaseService from "./baseService";

export default class NotesService extends BaseService {
  constructor() {
    super("notes");
  }

  async getPropertyAll(propertyId: string): Promise<Note[]> {
    return new Promise((resolve) => {
      const now = new Date();
      const futureDate = new Date();
      futureDate.setHours(now.getHours() + 1);
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tristique quam justo, eget iaculis lorem imperdiet vel. Nam dui felis, dignissim id turpis et, iaculis vestibulum quam. Pellentesque vestibulum facilisis libero, vitae auctor nunc ornare id. Praesent congue lorem id massa eleifend sagittis. Quisque eu tristique purus. Phasellus metus ligula, fringilla vel est ut, ullamcorper iaculis diam. Cras vel ex augue. In hac habitasse platea dictumst. Mauris volutpat eu tellus vitae pellentesque.",
              createdAt: now,
            },
            {
              id: "2",
              note: "Second note",
              createdAt: futureDate,
            },
          ]),
        1000
      );
    });
  }

  async getBuyerAll(buyerId: string): Promise<Note[]> {
    return new Promise((resolve) => {
      const now = new Date();
      const futureDate = new Date();
      futureDate.setHours(now.getHours() + 1);
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tristique quam justo, eget iaculis lorem imperdiet vel. Nam dui felis, dignissim id turpis et, iaculis vestibulum quam. Pellentesque vestibulum facilisis libero, vitae auctor nunc ornare id. Praesent congue lorem id massa eleifend sagittis. Quisque eu tristique purus. Phasellus metus ligula, fringilla vel est ut, ullamcorper iaculis diam. Cras vel ex augue. In hac habitasse platea dictumst. Mauris volutpat eu tellus vitae pellentesque.",
              createdAt: now,
            },
            {
              id: "2",
              note: "Second note",
              createdAt: futureDate,
            },
          ]),
        1000
      );
    });
  }
}
