import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "./pages/Login/reducer";
import propertyReducer from "./pages/Assets/reducer";
import buyerReducer from "./pages/Customers/reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  property: propertyReducer,
  buyer: buyerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
