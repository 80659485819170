import { Spinner } from "flowbite-react";

const THEME = {
  base: "inline animate-spin text-gray-200",
  color: {
    warning: "fill-highlighted",
  },
  size: {
    xl: "h-10 w-10",
  },
};
export function Loader() {
  return <Spinner color="warning" theme={THEME} size="xl" />;
}
