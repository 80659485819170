import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CalendarEvent } from "../../types";
import ServiceProvider from "../../services/provider";
import { Loader } from "../../components/Loader/Loader";
import "./Calendar.css";

const localizer = dayjsLocalizer(dayjs);

const CalendarPage = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    ServiceProvider.Calendar.getAll("123")
      .then(setEvents)
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="calendar-container">
      {loading ? (
        <Loader />
      ) : (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      )}
    </div>
  );
};

export default CalendarPage;
