import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "../../services/provider";
import { getError } from "../../utils/errors";
import {
  City,
  Extra,
  Neighborhood,
  PropertyFilter,
  PropertyItem,
  PropertyType,
  Tag,
} from "../../types";

export interface PropertyDataSource {
  data: PropertyItem[];
  count: number;
  loading: boolean;
}

export interface PropertyStore {
  filter: PropertyFilter | null;
  items: PropertyDataSource;
  error: string | null;
  options: {
    tags: Tag[];
    propertyTypes: PropertyType[];
    cities: City[];
    neighborhoods: Neighborhood[];
    extras: Extra[];
    loading: boolean;
  };
}

const initialState: PropertyStore = {
  filter: null,
  items: { data: [], count: 0, loading: false },
  error: null,
  options: {
    tags: [],
    propertyTypes: [],
    cities: [],
    neighborhoods: [],
    extras: [],
    loading: true,
  },
};

export const getFilterOptionsAsync = createAsyncThunk(
  "property/filterOptions",
  async (f) => {
    return Promise.all([
      ServiceProvider.Tag.getAll(),
      ServiceProvider.Property.getPropertyTypes(),
      ServiceProvider.City.getAll(),
      ServiceProvider.Neighborhood.getAll(),
      ServiceProvider.Extra.getAll(),
    ]);
  }
);

export const getPropertyDataSourceAsync = createAsyncThunk(
  "property/getPropertyItems",
  async (filter: PropertyFilter | null, thunkAPI) => {
    thunkAPI.dispatch(setFilter(filter));
    return await ServiceProvider.Property.getAll(filter);
  }
);

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilterOptionsAsync.pending, (state) => {
        state.options.loading = true;
      })
      .addCase(getFilterOptionsAsync.fulfilled, (state, action) => {
        const data = get(action, "payload", null);
        state.options.tags = data ? data[0] : [];
        state.options.propertyTypes = data ? data[1] : [];
        state.options.cities = data ? data[2] : [];
        state.options.neighborhoods = data ? data[3] : [];
        state.options.extras = data ? data[4] : [];
        state.options.loading = false;
      })
      .addCase(getFilterOptionsAsync.rejected, (state, action) => {
        state.options = {
          tags: [],
          propertyTypes: [],
          cities: [],
          neighborhoods: [],
          extras: [],
          loading: false,
        };
        state.options.loading = false;
        state.error = getError(action);
      })
      .addCase(getPropertyDataSourceAsync.pending, (state) => {
        state.items.loading = true;
      })
      .addCase(getPropertyDataSourceAsync.fulfilled, (state, action) => {
        const response = get(action, "payload", {
          count: 0,
          results: [],
        });
        state.items.data = response.results;
        state.items.count = response.count;
        state.items.loading = false;
      })
      .addCase(getPropertyDataSourceAsync.rejected, (state, action) => {
        state.items.data = [];
        state.items.count = 0;
        state.items.loading = false;
        state.error = getError(action);
      });
  },
});

export const { setFilter } = propertySlice.actions;

export const makePropertyFilter = (state: { property: PropertyStore }) =>
  state.property.filter;

export const makeFilterOptions = (state: { property: PropertyStore }) =>
  state.property.options;

export const makePropertyDataSource = (state: { property: PropertyStore }) =>
  state.property.items;

export default propertySlice.reducer;
