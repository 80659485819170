import { Buyer, Sex } from "../types";
import BaseService from "./baseService";

export default class BuyerService extends BaseService {
  constructor() {
    super("buyer");
  }

  async getAll(agentId: string): Promise<Buyer[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              fullName: "John Doe",
              agentId: "Eugene",
              sex: Sex.Male,
              email: "test1@gmail.com",
              mobile: "+1234567890",
              buyerId: "123",
              address: "Main str. 123",
              description: "Some description",
              preferences: {
                floorFrom: 2,
                floorTo: 3,
                roomsFrom: 1,
                roomsTo: 3,
                priceFrom: 100,
                priceTo: 1000,
              },
              groupIds: ["gr1", "gr3"],
              registeredAt: new Date(),
            },
            {
              id: "2",
              fullName: "Jane Doe",
              agentId: "Eugene",
              sex: Sex.Male,
              email: "test2@gmail.com",
              mobile: "+7774567890",
              buyerId: "666",
              address: "Main str. 666",
              description: "Some description 2",
              preferences: null,
              groupIds: ["gr1", "gr2"],
              registeredAt: new Date(),
            },
            {
              id: "3",
              fullName: "JJJ ZZZ",
              agentId: "Eugene",
              sex: Sex.Other,
              email: "test3@gmail.com",
              mobile: "+6664567890",
              buyerId: "777",
              address: "Main str. 777",
              description: "Some description 3",
              preferences: null,
              groupIds: ["gr2", "gr1"],
              registeredAt: new Date(),
            },
          ]),
        1000
      );
    });
  }
}
