import { isObject, isArray, isUndefined, isDate } from "lodash";
import config from "../config";
import { Pages } from "../enums/pages";

export default class BaseService {
  protected controllerUrl: string;

  constructor(controllerUrl: string) {
    this.controllerUrl = controllerUrl;
  }

  async fetchData(
    endPoint: string,
    options: any = {},
    isExternalService: boolean
  ) {
    const optionalSettings: any = {};
    if (!isExternalService) {
      optionalSettings["credentials"] = "include";
    }
    const requestOptions = {
      headers: {
        ...options.headers,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: options.method || "GET",
      body: options.body,
      ...optionalSettings,
    };
    let urlParts;
    if (isExternalService) {
      urlParts = [endPoint];
    } else {
      urlParts = this.controllerUrl
        ? [config.apiUrl, this.controllerUrl, endPoint]
        : [config.apiUrl, endPoint];
    }
    return fetch(
      urlParts.filter((v) => v!.length > 0).join("/"),
      requestOptions
    );
  }

  async api(
    endPoint: string,
    options: any = {},
    isExternalService: boolean,
    isFileDownload: boolean = false
  ) {
    const { headers, ...rest } = options;
    let url = endPoint;
    if (!rest.method && Object.keys(rest).length > 0) {
      url = `${endPoint}?${this.prepareRequestQuery("", rest, true)}`;
    }
    return this.fetchData(
      url,
      rest.method ? options : { headers: headers },
      isExternalService
    )
      .then(async (response) => {
        if ([403, 401].includes(response.status)) {
          if (window.location.pathname.indexOf(Pages.Login) === -1)
            window.location.replace(Pages.Login);
          return Promise.resolve(null);
        }

        if (response.ok) {
          if (response.status === 204) {
            return Promise.resolve(null);
          }

          if (isFileDownload) {
            await BaseService.processFileDownload(response);
            return Promise.resolve(null);
          } else {
            const res = await response.json();

            if (response.status !== 200 && res.error) {
              throw new Error(res.error);
            }
            return res;
          }
        }

        const res = await response.json();
        if (response.status !== 200 && res.error) {
          throw new Error(
            JSON.stringify(
              isArray(res.error) ? res.error : [{ msg: res.error }]
            )
          );
        }

        return res;
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  static async processFileDownload(response: Response) {
    const contentDisposition = response.headers.get("Content-Disposition");
    const filenameMatch =
      contentDisposition && contentDisposition.match(/filename="(.+)"/);

    const filename = filenameMatch ? filenameMatch[1] : "output.csv";

    const blob = await response.blob();
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static getRequestOptions(httpMethod: string, data: any = null) {
    const options: {
      method: string;
      body?: any;
    } = {
      method: httpMethod,
    };
    if (data) {
      options.body = JSON.stringify(data);
    }

    return options;
  }

  async post(
    endPoint: string,
    data: any = null,
    isExternalService: boolean = false
  ) {
    return this.api(
      endPoint,
      BaseService.getRequestOptions("POST", data),
      isExternalService
    );
  }

  async delete(
    endPoint: string,
    data: any = null,
    isExternalService: boolean = false
  ) {
    return this.api(
      endPoint,
      BaseService.getRequestOptions("DELETE", data),
      isExternalService
    );
  }

  async put(
    endPoint: string,
    data: any = null,
    isExternalService: boolean = false
  ) {
    return this.api(
      endPoint,
      BaseService.getRequestOptions("PUT", data),
      isExternalService
    );
  }

  prepareRequestQuery = (prefix: string, val: any, top: boolean): string => {
    if (isUndefined(top)) top = true;
    if (isArray(val)) {
      return val
        .map((value: any, key: number) => {
          return this.prepareRequestQuery(
            top ? key.toString() : `${prefix}[]`,
            value,
            false
          );
        })
        .join("&");
    } else if (isDate(val)) {
      return `${encodeURIComponent(prefix)}=${(val as Date).toISOString()}`;
    } else if (isObject(val)) {
      return Object.keys(val)
        .map((key: string) => {
          return this.prepareRequestQuery(
            top ? key : `${prefix}[${key}]`,
            val[key as keyof typeof val], // Type assertion here
            false
          );
        })
        .join("&");
    } else {
      return `${encodeURIComponent(prefix)}=${encodeURIComponent(val)}`;
    }
  };
}
