import BaseService from "./baseService";

export default class AuthService extends BaseService {
  constructor() {
    super("auth");
  }

  async login(email: string, password: string) {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            email,
            password,
            name: "Test",
          }),
        100
      );
    });
  }

  async logout() {
    return new Promise((resolve) => {
      setTimeout(() => resolve(1), 1000);
    });
  }

  async getProfile() {
    return super.post(`profile`);
  }
}
