import AuthService from "./authService";
import BuyerService from "./buyerService";
import CalendarService from "./calendarService";
import CityService from "./cityService";
import DocumentService from "./documentService";
import ExtraService from "./extraService";
import NeighborhoodService from "./neighborhoodService";
import NotesService from "./notesService";
import PropertyService from "./propertyService";
import TagService from "./tagService";
import TasksService from "./tasksService";

export default class ServiceProvider {
  static Auth = new AuthService();
  static Tag = new TagService();
  static Property = new PropertyService();
  static City = new CityService();
  static Neighborhood = new NeighborhoodService();
  static Extra = new ExtraService();
  static Note = new NotesService();
  static Task = new TasksService();
  static Document = new DocumentService();
  static Buyer = new BuyerService();
  static Calendar = new CalendarService();
}
