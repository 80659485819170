import { FlowbiteCheckboxTheme } from "flowbite-react";
import { DeepPartial } from "flowbite-react/dist/types/types";

const checkboxTheme: DeepPartial<FlowbiteCheckboxTheme> = {
  root: {
    base: "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 dark:border-gray-600 dark:bg-gray-700",
    color: {
      default: "text-highlighted focus:ring-cyan-600",
    },
  },
};

export default checkboxTheme;
